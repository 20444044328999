<app-navbar-one></app-navbar-one>

<div class="saas-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Más que una agencia, <span class="text-koaj">una desarrolladora.</span></h1>
                            <p>Contar con Koaj es invertir en tu negocio. Queremos ayudarte a escalar tus proyectos de forma estratégica y efectiva.</p>
                            
                            <div class="saas-btn">
                                <a routerLink="/contact" class="saas-btn-one">Contacto <i class="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img class="main-image" src="assets/img/saas-banner/solution.svg" alt="image">
                        </div>
                    </div>
                    
                    <!--
                    <div class="circle-img">
                        <img src="assets/img/saas-banner/circle3.png" alt="image">
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div>
</div>

<section class="features-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/saas-banner/business_plan.svg" alt="image">

                    <div class="features-shape">
                        <img src="assets/img/features/shapee.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="features-arrow">
                        <img src="assets/img/features/features-img2.png" class="wow fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-area-content">
                    <h2>¿Qué es <span class="text-koaj">Koaj</span>?</h2>
                    <p>Somos una desarrolladora de negocios enfocados en potenciar emprendimientos, profesionales y diferentes tipos de negocios. Nos enfocamos en impulsar tu negocio mediante herramientas de Marketing Digital y Desarrollo Web.</p>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3">
                                <div class="icon">
                                    <i class="flaticon-lock"></i>
                                </div>
                                <h3>Crecimiento exponencial</h3>
                                <p>Todo negocio lleva un proceso, en Koaj te ayudamos a superar tus objetivos.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-ffe7dc">
                                <div class="icon">
                                    <i class="flaticon-innovation"></i>
                                </div>
                                <h3>Actitud emprendedora</h3>
                                <p>Los desafíos nos apasionan, juntos podemos llevar tus ideas a cabo.</p>
                            </div>
                        </div>
                    </div>

                    <div class="features-btn">
                        <a routerLink="/about" class="default-btn">¿Qué hacemos? <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="benefit-section ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>¿Por qué <span class="text-koaj">Koaj</span>?</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="fas fa-network-wired"></i>
                    <h3>Experiencia</h3>
                    <p>Contamos con profesionales altamente capacitados con experiencia en el desarrollo de los medios digitales de diferentes negocios.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="fas fa-check"></i>
                    <h3>Resultados positivos</h3>
                    <p>Vivimos en un mundo en donde los negocios que se encuentran en los medios digitales de forma adecuada poseen un mayor acceso a nuevos clientes y un aumento exponencial en sus ventas.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="fas fa-microchip"></i>
                    <h3>Atentos a las últimas tendencias tecnológicas</h3>
                    <p>Nuestro equipo busca constantemente estar enfocados en las últimas tendencias del mundo digital permitiéndonos trabajar para vos con las mejores herramientas tecnológicas.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="fas fa-headset"></i>
                    <h3>Ateción prioritaria</h3>
                    <p>Cada uno de nuestros clientes es importante por lo que nuestros equipo realizará seguimientos constantes y te brindará asesorías que permitan mejores resultados para tu negocio.</p>
                </div>
            </div>
        </div>
        <div class="services-btn text-center">
            <a routerLink="/services" class="default-btn">Servicios <i class="fa fa-arrow-right"></i></a>
        </div>
    </div>
</section>

<section class="support-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/saas-banner/phone_development.svg" alt="image">

                    <div class="support-shape">
                        <img src="assets/img/features/shapee.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="support-arrow">
                        <img src="assets/img/support/support-img3.png" class="wow fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-area-content">
                    <h3>¿Cómo?</h3>
                    <h4 class="text-koaj">Crecimiento estratégico personalizado</h4>
                    <p>Nuestro equipo se interiorizará en tu negocio buscando conocer tus objetivos con el fin de crear de forma estratégica un plan de trabajo que permitan aumentar tus ventas, tu posicionamiento online y tus clientes.</p>

                    <p>Posicionamos tu negocio en las redes sociales teniendo en cuenta las últimas tendencias.</p>

                    <p>Crearemos contenido de calidad el cual será pensado y planificado con tiempo por nuestro equipo y luego preaprobado por cada uno de nuestros clientes.</p>

                    <p>Desarrollaremos el website más adecuado con las funcionalidades que mejor se adapten a tus necesidades pudiendo desarrollar desde una landing page hasta un ecommerce (tienda online).</p>

                    <p>Daremos soporte y mantenimiento para que puedas tener tus medios digitales operando siempre en las mejores condiciones y brindando atención por miembros de nuestro equipo a todas tus dudas.  </p>


                    <div class="support-btn">
                        <a routerLink="/pricing" class="default-btn">Conocé nuestros planes <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


