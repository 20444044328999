<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Desarrollo Web</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Desarrollo Web</h3>
                        <p>El desarrollo web es el proceso de creación y mantenimiento de sitios web. Mediante un análisis de tus objetivos cómo negocio nuestro equipo te brindará las mejores opciones para vos y tus clientes.</p>

                        <p>¿Necesitas una <strong>presencia en línea efectiva</strong> para tu negocio?
                        ¡Tenemos la solución perfecta para vos! Ofrecemos servicios de desarrollo web personalizados para crear una página web que se adapte a las <strong>necesidades de tu negocio.</strong></p>

                        <p>Con nuestra experiencia en desarrollo web, podemos crear una página web que no solo sea visualmente atractiva, sino que también sea fácil de usar con una navegación amigable para los usuarios. Nos aseguramos de que tu sitio web esté optimizado tanto en velocidad como en eficiencia, lo que significa que tus clientes tendrán una experiencia rápida y sin problemas en línea.</p>

                        
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/web_1.svg" alt="Web Development">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/web_2.svg" alt="Web Development">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>La mejor opción para tu negocio</h3>

                        <p>Nuestro equipo de diseñadores y desarrolladores <strong>trabajará con vos en cada etapa del proceso</strong> de creación de la página web para asegurarnos de que se cumplan todos tus requisitos y objetivos. Ofrecemos una amplia gama de servicios de desarrollo web, desde la creación de un sitio web simple hasta soluciones de comercio electrónico más complejas.</p>

                        <p>No pierdas la oportunidad de tener una <strong>presencia en línea efectiva</strong> para tu negocio, mediante un análisis de tus objetivos cómo negocio nuestro equipo de programadores y desarrolladores evaluará las mejores opciones para vos y tus clientes.</p>

                        <p>Nuestros principales servicios de <strong>diseño y desarrollo:</strong></p>
                        <ul>
                            <li><p>Landing Page</p></li>
                            <li><p>Website</p></li>
                            <li><p>Proyectos <strong>especiales personalizados</strong></p></li>
                            <li><p>Dominio web (ejemplo: tuempresa.com) </p></li>
                            <li><p>Dominio mail (ejemplo: hola@tuempresa.com)</p></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>


<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        +<span class="odometer" data-count="70">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>de la población de LATAM usa Internet</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        +<span class="odometer" data-count="125">00</span>
                        <span class="sign-icon">M USD</span>
                    </h3>
                    <p>en ventas online en LATAM</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        +<span class="odometer" data-count="300">00</span>
                        <span class="sign-icon">M</span>
                    </h3>
                    <p>de personas han comprado en tiendas online en el último tiempo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="5">0</span>
                        <span class="sign-icon">hs</span>
                    </h3>
                    <p>por día pasan los usuarios en redes sociales en LATAM</p>
                </div>
            </div>

        </div>
    </div>
</section>