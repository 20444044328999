<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Asesoramiento</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Asesoramiento</h3>
                        <p>En Koaj estamos seguros que <strong>las grandes ideas pueden lograr grandes resultados</strong>. Nuestro principal propósito es que puedas desarrollar tu negocio y crezcas pudiendo cumplir todas tus metas y creemos que para que eso suceda <strong>el apoyo de expertos en áreas tan importantes es clave</strong>. Al contar con Koaj contras con un equipo multidisciplinario que estará dispuesto a asesorarte y apoyarte en todo tu proceso de crecimiento.</p>


                        <p>¿Tienes un negocio pero no sabes cómo hacerlo crecer? ¿Te gustaría tener asesoramiento que te encamine hacia el éxito de tu negocio? Entonces, ¡nuestro servicio de asesoramiento es justo lo que necesitas!</p>

                        <p>En el mundo digital actual, es crucial tener una estrategia sólida para tu negocio. Desde el Counseling digital hasta la optimización del sitio web y la gestión de redes sociales, hay muchas áreas en las que necesitas ser experto para tener éxito en línea. Pero, ¿qué pasa si no tienes experiencia en estos temas? ¡No te preocupes! Nuestros asesores expertos en negocios digitales están aquí para ayudarte.</p>
                        
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/counseling_1.svg" alt="Makreting">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/counseling_2.svg" alt="Counseling">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Te acompañamos para lograr tus objetivos</h3>

                        <p>El equipo de asesores tiene años de experiencia en el mundo digital y ha trabajado con una amplia gama de negocios. Ya sea que estés comenzando tu negocio digital desde cero o que necesites ayuda para llevar tu negocio al siguiente nivel, estamos aquí para ayudarte. Nuestros asesores se encuentran para ofrecerte soluciones personalizadas y ayudarte a alcanzar tus objetivos.</p>

                        <p>Nos aseguramos de que cada uno de nuestros servicios esté diseñado para satisfacer las necesidades únicas de tu negocio, proporcionándote soluciones eficientes y eficaces para cada desafío empresarial que puedas enfrentar.</p>

                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>


<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Mayor confianza</span>
                    </h3>
                    <p>al tomar decisiones complejas</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Enfoque</span>
                    </h3>
                    <p>en tus intereses y objetivos</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Optimización</span>
                    </h3>
                    <p>de tiempo y recursos</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Experiencia</span>
                    </h3>
                    <p>de nuestro equipo multidisciplinario</p>
                </div>
            </div>

        </div>
    </div>
</section>