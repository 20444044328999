<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Servicios</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <h3>Llevemos juntos tu negocio al <strong>siguiente nivel</strong></h3>
                    <p>Desde el diseño atractivo y funcional hasta las estrategias de marketing digital efectivas, trabajaremos juntos para llevar tu negocio a la cima de su potencial. Con nuestras soluciones personalizadas y experiencia en el área, te ayudaremos a destacar entre la competencia y atraer a nuevos clientes.</p>

                    <p>¡Comencemos juntos a escalar tu negocio hoy mismo!</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/img/services/services.svg" alt="Team">
                </div>
            </div>
        </div>
    </div>
</section>

<app-services-layout [details]="true"></app-services-layout>
