<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img width="61" src="assets/img/logo/logo-color.png" alt="Koaj">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img width="61" src="assets/img/logo/logo-color.png" alt="Koaj">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul 
                        class="navbar-nav" 

                    >

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inicio</a></li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">¿Qué hacemos?</a></li>

                        <li class="nav-item">
                            <a routerLink="/services" class="nav-link">Servicios <i class="fa fa-angle-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/digital-marketing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Marketing Digital</a></li>

                                <li class="nav-item"><a routerLink="/web-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Desarrollo Web</a></li>

                                <li class="nav-item"><a routerLink="/strategy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Planificación estratégica</a></li>

                                <li class="nav-item"><a routerLink="/counseling" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Asesoramiento</a></li>

                                <li class="nav-item"><a routerLink="/analysis" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Análisis de resultados</a></li>

                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Planes</a></li>

                        <!--<li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>-->

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contacto</a></li>


                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>