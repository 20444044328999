import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-counseling',
  templateUrl: './counseling.component.html',
  styleUrls: ['./counseling.component.scss']
})
export class CounselingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
