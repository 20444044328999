<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Planificación Estratégica</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Planificación Estratégica</h3>
                        <p>En Koaj <strong>lo central sos vos y tus clientes</strong> por lo cual queremos escucharte, analizar cuáles son tus objetivos y juntos diseñar los pasos a seguir de forma adecuada y responsable teniendo en cuenta todos los factores del mercado y así poder lograr excelentes resultados de <strong>forma exponencial</strong>.</p>

                        <p>¿Quieres que tu empresa <strong>alcance sus objetivos</strong> de manera efectiva y eficiente?</p>

                        <p>La planificación estratégica es la clave para lograrlo, es una herramienta clave para cualquier empresa que busque crecer y desarrollarse en un mercado cada vez más competitivo. Permite una visión integral del negocio y la identificación de las mejores oportunidades para alcanzar los objetivos deseados.</p>
                        
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/strategy_1.svg" alt="Strategy">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/strategy_2.svg" alt="Strategy">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Cumplimos tus metas</h3>
                        
                        <p>Nuestro servicio de planificación estratégica te ayudará a <strong>definir tus objetivos a corto, mediano y largo plazo</strong>, identificar los recursos necesarios para alcanzarlos y diseñar un plan de acción claro y realista para su implementación. Mediante una planificación estratégica podrás tomar decisiones informadas para maximizar tus recursos y aumentar tus posibilidades de éxito.</p>

                        <p>El equipo de Koaj mantendrá una comunicación constante con los miembros de tu negocio por lo que contarás con su <strong>apoyo frente a cualquier acción a tomar</strong>. No esperes más para mejorar el rendimiento de tu empresa y asegurar su crecimiento en el corto, mediano y largo plazo. </p>

                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>


<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Análisis estratégico</span>
                    </h3>
                    <p>de la situación actual y futura</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Diseñamos y desarrollamos</span>
                    </h3>
                    <p>los pasos a llevar a cabo</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Implementamos</span>
                    </h3>
                    <p>la estrategia</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Realizamos un análisis</span>
                    </h3>
                    <p>de los resultados</p>
                </div>
            </div>

        </div>
    </div>
</section>