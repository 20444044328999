<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>¿Qué hacemos?</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/img/about_1.svg" alt="Team">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>Sobre nosotros</span>
                    <h3>Impulsamos tu negocio de forma estratégica</h3>
                    <p>Nos dedicamos a impulsar el crecimiento de tu negocio en base a los objetivos que poseas y en base a los mismos diseñar una estrategia que permita poder cumplirlos.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>¿Qué hacemos?</span>
                    <h3>Te brindamos productos y servicios</h3>
                    <p>Nuestro equipo de profesionales se encargará de dar seguimiento constante a cada proyecto, te mantendrá al tanto de cualquier novedad, modificación recomendada y te brindará asistencia técnica en caso de ser necesario.</p>
                    <p>Poseemos diferentes planes para que elijas cuál se adapta mejor a tus necesidades y un plan personalizado (DIAMOND) que podremos crear juntos para que contemple todas tus necesidades.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/about_2.svg" alt="Team">
                </div>
            </div>
        </div>
    </div>
</section>


<!--
<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="100">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>Completed projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="253">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Customer satisfaction</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="24">00</span>
                        <span class="sign-icon">h</span>
                    </h3>
                    <p>Expert Support Team</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="78">00</span>
                        <span class="sign-icon">k</span>
                    </h3>
                    <p>Sales in Count</p>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<app-services-layout></app-services-layout>
