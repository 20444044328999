<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Marketing Digital</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Marketing Digital</h3>
                        <p>El marketing digital es un sistema que permite promocionar tus productos o servicios en el ecosistema digital, utilizando canales y herramientas en línea de forma estratégica con el fin de lograr tus objetivos y así crecer de forma exponencial llegando a más clientes.</p>

                        <p>¿Quieres llevar tu negocio al siguiente nivel en línea? ¡Tenemos el servicio perfecto para vos!</p>

                        <p>Ofrecemos servicios de marketing digital personalizados para ayudarte a alcanzar tus objetivos y hacer crecer tu negocio.</p>					
                        
                        <p>Con nuestra experiencia en marketing digital, podemos ayudarte a llegar a tu público objetivo y aumentar la visibilidad de tu marca en las redes sociales. Nos aseguramos de que tu estrategia de marketing digital sea personalizada y efectiva para tu negocio, utilizando las últimas herramientas y técnicas para maximizar tus resultados.</p>

                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/marketing_1.svg" alt="Makreting">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/marketing_2.svg" alt="Marketing">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Soluciones a tu medida</h3>

                        <p>Nuestro equipo de expertos en marketing digital se asegurará de que tu inversión sea rentable y que obtengas un retorno de inversión positivo. Nos mantenemos actualizados con las últimas tendencias y técnicas en marketing digital para asegurarnos de que tu negocio siempre tenga una ventaja competitiva en línea.</p>    
                        
                        <p>No pierdas la oportunidad de llevar tu negocio al siguiente nivel y comienza a ver los resultados de forma exponencial que deseas.</p>
        
                        <ul>
                            <li><p>Gestionamos tus <strong>Redes Sociales</strong> (Instagram, Facebook, TikTok, Twitter, LinkedIn)</p></li>
                            <li><p>Planificamos estratégicamente tus <strong>medios digitales</strong> basada en tendencias y resultados</p></li>
                            <li><p>Diseñamos tus posteos / reels </p></li>
                        </ul>
                    
                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>

<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="9600">00</span>
                        <span class="sign-icon">M USD</span>
                    </h3>
                    <p>de inversión en MKT Digital en LATAM</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="83">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>de la población en LATAM tiene acceso a una red social</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="70">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>de los consumidores confían en los productos que ven en redes sociales</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="5">0</span>
                        <span class="sign-icon">hs</span>
                    </h3>
                    <p>por día pasan los usuarios en redes sociales en LATAM</p>
                </div>
            </div>

        </div>
    </div>
</section>