<div class="partner-section py-5">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a routerLink="/digital-marketing" class="d-block">
                    <img src="assets/img/services/marketing_3.png" alt="Marketing Digital">
                    <h5 class="pt-3">Marketing Digital</h5>
                    <p *ngIf="details">Creamos, planificamos, diseñamos, posteamos y gestionamos tus redes sociales. Con planes pensados para emprendedores, pymes y profesionales.</p>
                </a>
            </div>

            <div class="partner-item">
                <a routerLink="/web-development" class="d-block">
                    <img src="assets/img/services/web_3.png" alt="Desarrollo Web">
                    <h5 class="pt-3">Desarrollo Web</h5>
                    <p *ngIf="details">Diseñamos un sitio web personalizado para tu negocio, pensando en tu empresa y consumidores.</p>
                </a>
            </div>

            <div class="partner-item">
                <a routerLink="/strategy" class="d-block">
                    <img src="assets/img/services/strategy_3.png" alt="Planificación estratégica">
                    <h5 class="pt-3">Planificación</h5>
                    <p *ngIf="details">Diseñamos una estrategia con el fin de lograr tus objetivos pensando tanto en vos y en tus clientes.</p>
                </a>
            </div>

            <div class="partner-item">
                <a routerLink="/counseling" class="d-block">
                    <img src="assets/img/services/counseling_3.png" alt="Asesoramiento">
                    <h5 class="pt-3">Asesoramiento</h5>
                    <p *ngIf="details">Te escuchamos, organizamos tus conceptos e ideas y creamos una marca relevante y un plan de negocio estratégico.</p>
                </a>
            </div>

            <div class="partner-item">
                <a routerLink="/analysis" class="d-block">
                    <img src="assets/img/services/analysis_3.png" alt="Análisis">
                    <h5 class="pt-3">Análisis de resultados</h5>
                    <p *ngIf="details">Luego de poner en práctica la estrategia nos encargamos de interpretar los resultados con el fin de tomarlos en cuenta para las próximas acciones.</p>
                </a>
            </div>
        </div>
    </div>
</div>