<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Planes</h2>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="demo">
    <div class="container">

        <h1 class="text-center mb-0 py-5">Contamos con diferentes planes que se adaptan a <strong>tus necesidades</strong></h1>

        <div class="row justify-content-center">
            <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <h3 class="heading">Bronze</h3>
                        
                        <div class="price-value">149
                            <span class="currency">USD</span>
                            <span class="month">/mes</span>
                        </div>
                    </div>
                    <ul class="pricing-content px-2">
                        <li>Ideal para comenzar</li>
                        <li>Creación de cuentas desde cero</li>
                        <li>Una publicación semanal en 2 redes sociales</li>
                        <li>5 historias semanales</li>
                        <li>Opción de 1 Reel semanal</li>
                        <li>Planificación estratégica</li>
                        <li>Asesorías y actualización de resultados</li>
                        <li>Soporte técnico disponible</li>
                    </ul>
                    <button data-toggle="modal" data-target="#bronze" class="read">Ver más<i class="fa fa-angle-right"></i></button>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <h3 class="heading">Silver</h3>
                        
                        <div class="price-value">199
                            <span class="currency">USD</span>
                            <span class="month">/mes</span>
                        </div>
                    </div>
                    <ul class="pricing-content px-2">
                        <li>Ideal para aumentar tu presencia</li>
                        <li>3 publicaciones semanales en 2 redes sociales</li>
                        <li>7 historias semanales</li>
                        <li>Opción de 2 Reels semanales</li>
                        <li>Website (One Page) y Dominio web</li>
                        <li>Planificación estratégica</li>
                        <li>Asesorías y actualización de resultados</li>
                        <li>Soporte técnico disponible</li>
                    </ul>
                    <button data-toggle="modal" data-target="#silver" class="read">Ver más<i class="fa fa-angle-right"></i></button>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <h3 class="heading">Gold</h3>
                        
                        <div class="price-value">239
                            <span class="currency">USD</span>
                            <span class="month">/mes</span>
                        </div>
                    </div>
                    <ul class="pricing-content px-2">
                        <li>Perfecto para crecer tu negocio</li>
                        <li>5 publicaciones semanales en 2 o 3 redes sociales</li>
                        <li>10 historias semanales</li>
                        <li>Opción de 3 Reels semanales</li>
                        <li>Website (Multipágina) y Dominio</li>
                        <li>Planificación estratégica</li>
                        <li>Asesorías y actualización de resultados</li>
                        <li>Soporte técnico disponible</li>
                    </ul>
                    <button data-toggle="modal" data-target="#gold" class="read">Ver más<i class="fa fa-angle-right"></i></button>
                </div>
            </div>

            <div class="col-md-4 col-sm-6 mt-md-5">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <div class="ribbon ribbon-top-right"><span>Más popular</span></div>
                        <h3 class="heading">Platinum</h3>
                        
                        <div class="price-value">299
                            <span class="currency">USD</span>
                            <span class="month">/mes</span>
                        </div>
                    </div>
                    <ul class="pricing-content px-2">
                        <li>Excelente para llevar tu negocio a otro nivel</li>
                        <li>7 publicaciones semanales en 2 o 3 redes sociales</li>
                        <li>14 historias semanales</li>
                        <li>Opción de 4 Reels semanales</li>
                        <li>Website (Landing o ecommerce) y Dominio</li>
                        <li>Dominio mail</li>
                        <li>Planificación estratégica</li>
                        <li>Asesorías y actualización de resultados</li>
                        <li>Soporte técnico disponible</li>
                    </ul>
                    <button data-toggle="modal" data-target="#platinum" class="read">Ver más<i class="fa fa-angle-right"></i></button>
                </div>
            </div>

            <div class="col-md-4 col-sm-6 mt-md-5">
                <div class="pricingTable">
                    <div class="pricingTable-header">
                        <h3 class="heading">Diamond</h3>
                        
                        <div class="price-value-text">A Definir</div>
                    </div>
                    <ul class="pricing-content px-2">
                        <li>Personalizable en base a tus necesidades y objetivos</li>
                        <li>Publicaciones, Reels e historias</li>
                        <li>Publicidad en redes sociales y en buscador</li>
                        <li>Website Básicas y avanzadas</li>
                        <li>Dominio web y mail</li>
                        <li>Planificación estratégica</li>
                        <li>Asesorías y actualización de resultados</li>
                        <li>Soporte técnico disponible</li>
                        <li>Consultar por más servicios personalizados</li>
                    </ul>
                    <button data-toggle="modal" data-target="#diamond" class="read">Ver más<i class="fa fa-angle-right"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- BRONZE -->
<div class="modal fade" id="bronze" tabindex="-1" role="dialog" aria-labelledby="bronze" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="header d-flex justify-content-center align-items-center">
                <div class="col col-1">

                </div>
                <div class="col col-10 justify-content-center text-center">
                    <h3 class="mb-0 text-white">Bronze</h3>
                </div>
                <div class="col col-1 d-flex justify-content-center align-items-center pointer" data-dismiss="modal">
                    <i class="fa-li fa fa-times"></i>
                </div>
            </div>
            <div class="py-md-5 px-md-4 p-sm-3 p-4 pr-5">
                <div class="text-center">
                    <h4 class="mb-0 text-koaj">Ideal para comenzar!</h4>
                </div>
                <ul class="fa-ul mt-5">
                    <li><i class="fa-li fa fa-check"></i>Ideal para todo aquel emprendedor/profesional que se encuentre en los primeros pasos.</li>
                    <li><i class="fa-li fa fa-check"></i>Nuestro equipo creará una estrategia de marketing digital en la cual se tendrán en cuenta los próximos objetivos que cómo emprendimiento poseen.</li>
                    <li><i class="fa-li fa fa-check"></i>Contarás con una publicación semanal en dos redes sociales a convenir en base a los objetivos del emprendimiento. A su vez se publicarán tres historias semanalmente (las mismas son publicaciones específicas de 24hs de duración) en ambas redes sociales. Por otro lado en caso de considerarse efectivo para tu negocio se publicará un reel semanal (video de 15-20s de duración)</li>
                    <li><i class="fa-li fa fa-check"></i>En base al avance del tiempo nuestro equipo te mantendrá al tanto de los resultados obtenidos, nuevas estrategias recomendadas a llevar a cabo, entre otras asesorías.</li>
                    <li><i class="fa-li fa fa-check"></i>Este plan es perfecto para comenzar, nuestro equipo estará disponible para brindarte soporte técnico y responder todas tus dudas que puedan ir surgiendo.</li>
                </ul>
                <div class="text-center">
                    <h3 class="mb-0 mt-3 text-koaj">
                        USD 149/mes
                    </h3>
                    <a routerLink="/contact" class="read mt-4" data-dismiss="modal">Contacto<i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- SILVER -->
<div class="modal fade" id="silver" tabindex="-1" role="dialog" aria-labelledby="silver" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="header d-flex justify-content-center align-items-center">
                <div class="col col-1">

                </div>
                <div class="col col-10 justify-content-center text-center">
                    <h3 class="mb-0 text-white">Silver</h3>
                </div>
                <div class="col col-1 d-flex justify-content-center align-items-center pointer" data-dismiss="modal">
                    <i class="fa-li fa fa-times"></i>
                </div>
            </div>
            <div class="py-md-5 px-md-4 p-sm-3 p-4 pr-5">
                <div class="text-center">
                    <h4 class="mb-0 text-koaj">Ideal para aumentar tu presencia!</h4>
                </div>
                <ul class="fa-ul mt-5">
                    <li><i class="fa-li fa fa-check"></i>Ideal para todo aquel emprendedor/profesional/negocio que desee tener presencia intermedia en diferentes medios digitales.</li>
                    <li><i class="fa-li fa fa-check"></i>Se llevará a cabo mediante 3 publicaciones por semana + 7 historias. A su vez se publicarán 2 reels semanales.</li>
                    <li><i class="fa-li fa fa-check"></i>Por otro lado se creará una website en formato One Page (Web de una página que busca interacción del usuario, conocido como “call to action”)  con el dominio. (registro de tu negocio, ej: tuempresa.com).</li>
                    <li><i class="fa-li fa fa-check"></i>Nuestro equipo creará una estrategia de marketing digital en la cual se tendrán en cuenta los próximos objetivos que cómo negocio poseen.</li>
                    <li><i class="fa-li fa fa-check"></i>En base al avance del tiempo nuestro equipo te mantendrá al tanto de los resultados obtenidos, nuevas estrategias recomendadas a llevar a cabo, entre otras asesorías.</li>
                    <li><i class="fa-li fa fa-check"></i>Este plan es perfecto para llegar a nuevos clientes y dar a conocer tus productos/servicios; Nuestro equipo estará disponible para brindarte soporte técnico y responder todas tus dudas que puedan ir surgiendo.</li>
                </ul>
                <div class="text-center">
                    <h3 class="mb-0 mt-3 text-koaj">
                        USD 199/mes
                    </h3>
                    <a routerLink="/contact" class="read mt-4" data-dismiss="modal">Contacto<i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- GOLD -->
<div class="modal fade" id="gold" tabindex="-1" role="dialog" aria-labelledby="gold" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="header d-flex justify-content-center align-items-center">
                <div class="col col-1">

                </div>
                <div class="col col-10 justify-content-center text-center">
                    <h3 class="mb-0 text-white">Gold</h3>
                </div>
                <div class="col col-1 d-flex justify-content-center align-items-center pointer" data-dismiss="modal">
                    <i class="fa-li fa fa-times"></i>
                </div>
            </div>
            <div class="py-md-5 px-md-4 p-sm-3 p-4 pr-5">
                <div class="text-center">
                    <h4 class="mb-0 text-koaj">Perfecto para crecer tu negocio!</h4>
                </div>
                <ul class="fa-ul mt-5">
                    <li><i class="fa-li fa fa-check"></i>Ideal para todo aquel emprendedor/profesional/negocio que desee tener presencia significativa y crecer en diferentes medios digitales.</li>
                    <li><i class="fa-li fa fa-check"></i>En las redes sociales con una presencia significativa de 5 publicaciones por semana + 10 historias + 3 reels semanales.</li>
                    <li><i class="fa-li fa fa-check"></i>Por otro lado una website en formato Multipágina con tres páginas a elección más el formulario de contacto y el dominio (registro de tu negocio, ej: tuempresa.com).</li>
                    <li><i class="fa-li fa fa-check"></i>Nuestro equipo creará una estrategia de marketing digital en la cual se tendrán en cuenta los próximos objetivos que cómo negocio poseen.</li>
                    <li><i class="fa-li fa fa-check"></i>En base al avance del tiempo nuestro equipo te mantendrá al tanto de los resultados obtenidos, nuevas estrategias recomendadas a llevar a cabo, entre otras asesorías.</li>
                    <li><i class="fa-li fa fa-check"></i>Este plan es perfecto para tener una muy buena presencia en las redes sociales y una website útil, atractiva y profesional para tu negocio. Nuestro equipo estará disponible para brindarte soporte técnico y responder todas tus dudas que puedan ir surgiendo.</li>
                </ul>
                <div class="text-center">
                    <h3 class="mb-0 mt-3 text-koaj">
                        USD 239/mes
                    </h3>
                    <a routerLink="/contact" class="read mt-4" data-dismiss="modal">Contacto<i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- PLATINUM -->
<div class="modal fade" id="platinum" tabindex="-1" role="dialog" aria-labelledby="platinum" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="ribbon ribbon-top-left"><span>Más popular</span></div>

            <div class="header d-flex justify-content-center align-items-center">
                <div class="col col-1">

                </div>
                <div class="col col-10 justify-content-center text-center">
                    <h3 class="mb-0 text-white">Platinum</h3>
                </div>
                <div class="col col-1 d-flex justify-content-center align-items-center pointer" data-dismiss="modal">
                    <i class="fa-li fa fa-times"></i>
                </div>
            </div>
            <div class="py-md-5 px-md-4 p-sm-3 p-4 pr-5">
                <div class="text-center">
                    <h4 class="mb-0 text-koaj">Excelente para llevar tu negocio a otro nivel!</h4>
                </div>
                <ul class="fa-ul mt-5">
                    <li><i class="fa-li fa fa-check"></i>Ideal para todo aquel emprendedor/profesional/negocio que desee tener una excelente presencia en diferentes medios digitales, un nuevo canal de venta y llevar tu negocio al siguiente nivel.</li>
                    <li><i class="fa-li fa fa-check"></i>En las redes sociales con una presencia excelente de 7 publicaciones por semana + 14 historias + 4 Reels por semana.</li>
                    <li><i class="fa-li fa fa-check"></i>Por otro lado una website en formato ecommerce (tienda online) en la cual podrás ofrecer tus productos y tus clientes podrán adquirir los mismos por esa vía o darlos simplemente a conocer en una Landing Page.</li>
                    <li><i class="fa-li fa fa-check"></i>A su vez se proporcionará el Dominio web (tuempresa.com) y el Domino mail (hola@tuempresa.com)</li>
                    <li><i class="fa-li fa fa-check"></i>Nuestro equipo creará una estrategia de marketing digital en la cual se tendrán en cuenta los próximos objetivos que cómo negocio poseen.</li>
                    <li><i class="fa-li fa fa-check"></i>En base al avance del tiempo nuestro equipo te mantendrá al tanto de los resultados obtenidos, nuevas estrategias recomendadas a llevar a cabo, entre otras asesorías.</li>
                    <li><i class="fa-li fa fa-check"></i>Este plan es perfecto para tener una muy excelente presencia en las redes sociales y una website que te permita abrir un nuevo canal de venta que posicione a tu negocio en un nivel superior.</li>
                    <li><i class="fa-li fa fa-check"></i>Nuestro equipo estará disponible para brindarte soporte técnico y responder todas tus dudas que puedan ir surgiendo.</li>
                </ul>
                <div class="text-center">
                    <h3 class="mb-0 mt-3 text-koaj">
                        USD 299/mes
                    </h3>
                    <a routerLink="/contact" class="read mt-4" data-dismiss="modal">Contacto<i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- DIAMOND -->
<div class="modal fade" id="diamond" tabindex="-1" role="dialog" aria-labelledby="diamond" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="header d-flex justify-content-center align-items-center">
                <div class="col col-1">

                </div>
                <div class="col col-10 justify-content-center text-center">
                    <h3 class="mb-0 text-white">Diamond</h3>
                </div>
                <div class="col col-1 d-flex justify-content-center align-items-center pointer" data-dismiss="modal">
                    <i class="fa-li fa fa-times"></i>
                </div>
            </div>
            <div class="py-md-5 px-md-4 p-sm-3 p-4 pr-5">
                <div class="text-center">
                    <h4 class="mb-0 text-koaj">Personalizable en base a tus necesidades y objetivos!</h4>
                </div>
                <ul class="fa-ul mt-5">
                    <li><i class="fa-li fa fa-check"></i>Un plan hecho a medida en base a tus intereses y necesidades.</li>
                    <li><i class="fa-li fa fa-check"></i>Perfecto para poder plasmar todas tus ideas y necesidades.</li>
                    <li><i class="fa-li fa fa-check"></i>Es un plan que se arma en conjunto entre los objetivos, necesidades e intereses del negocio junto al equipo de Koaj que en base a su experiencia recomendará lo más adecuado.</li>
                    <li><i class="fa-li fa fa-check"></i>Se podrá armar un plan que contenga una determinada cantidad de publicaciones, historias y reels en diferentes redes sociales, publicidad en redes sociales, publicidad en medios de búsqueda y una website que puede ser desde una One Page, Multi Página, landing a un ecommerce (tienda virtual) y otras opciones personalizadas.</li>
                    <li><i class="fa-li fa fa-check"></i>Vinculado con el website podrás tener el Dominio del mismo (tuempresa.com) y el Domino Mail (hola@tuempresa.com)</li>
                    <li><i class="fa-li fa fa-check"></i>Obviamente que se contará con el apoyo constante del equipo de Koaj para abarcar dudas, dar soporte técnico y mantenerse al tanto de los resultados obtenidos, nuevas estrategias recomendadas a llevar a cabo, entre otras asesorías.</li>
                    <li><i class="fa-li fa fa-check"></i>Este Plan es ideal para que tu negocio tenga todo lo necesario para ser un éxito, llegar a nuevos clientes y tener un crecimiento exponencial.</li>
                </ul>
                <div class="text-center">
                    <a routerLink="/contact" class="read mt-4" data-dismiss="modal">Contacto<i class="fa fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

