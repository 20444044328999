import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-services-layout',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor() { }
  @Input() details: boolean;
  
  ngOnInit(): void {
  }

}
