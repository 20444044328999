import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  loading: boolean;
  submited: boolean = false;
  success: boolean = false;

  private myForm: AngularFirestoreCollection<any>;

  constructor(    
    private _formBuilder: FormBuilder,
    private _firestore: AngularFirestore
  ) 
  { }

  ngOnInit(): void {

    this.myForm= this._firestore.collection('contacts');

    this.contactForm = this._formBuilder.group({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      company: new FormControl(null),
      phone: new FormControl(null),
      message: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    console.log('Nombre:', this.contactForm.value);
    this.loading = true;
    this.submited = true

    if(this.contactForm.valid) {
      const value = {
        to: ['adrian@farjitecnologia.com'],
        message: {
          subject: 'KOAJ - Nuevo mensaje de '+this.contactForm.value.name,
          text: this.contactForm.value.email+' - Ha enviado un nuevo mensaje mediante la página de KOAJ.',
          html: `<p><strong>Nuevo mensaje recibio en el formulario de contacto de Koaj:</strong></p><p><strong>Remitente</strong>:&nbsp;${this.contactForm.value.name}</p><p><strong>Email de contacto:&nbsp;</strong>${this.contactForm.value.email}</p></p><p><strong>Teléfono (?)</strong>:&nbsp;${this.contactForm.value.phone}</p><p><strong>Compañia (?)</strong>:&nbsp;${this.contactForm.value.company}</p><p><strong>Mensaje:&nbsp;</strong>${this.contactForm.value.message}</p>`,
        }
      }
      this.myForm.add(value).then(
        res => {
          this.loading = false;
          this.success = true;
        },
        err => {
          this.loading = false;
          console.error(err);
        }
      )
    } else {
      this.loading = false;
    }

  }
}
