<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contacto</h2>

                </div>
            </div>
        </div>
    </div>
</div>


<section class="talk-section pb-100 pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="talk-form">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="!success">
                        <div class="form-group">
                            <input type="text" formControlName="name" name="name" class="form-control" placeholder="Nombre *" id="name" required>
                        </div>
                        <div *ngIf="contactForm.get('name')?.errors && submited" class="alert p-0">
                            <span *ngIf="contactForm.get('name')?.errors?.required">
                              Este campo es requerido
                            </span>
                        </div>

                        <div class="form-group">
                            <input type="email" formControlName="email" name="email" class="form-control" placeholder="Email *" id="email" required>
                        </div>
                        <div *ngIf="contactForm.get('email')?.errors && submited" class="alert p-0">
                            <span *ngIf="contactForm.get('email')?.errors?.required">
                              Este campo es requerido
                            </span>
                            <span *ngIf="contactForm.get('email')?.errors?.email">
                                El email no es válido
                              </span>
                        </div>

                        <div class="form-group">
                            <input type="number" formControlName="phone" name="phone" class="form-control" placeholder="Teléfono" id="phone">
                        </div>

                        <div class="form-group">
                            <input type="text" formControlName="company" name="company" class="form-control" placeholder="Empresa" id="company">
                        </div>

                        <div class="form-group">
                            <textarea name="message" formControlName="message" class="form-control" cols="30" rows="6" placeholder="Mensaje *" id="message" required></textarea>
                        </div>
                        <div *ngIf="contactForm.get('message')?.errors && submited" class="alert p-0">
                            <span *ngIf="contactForm.get('message')?.errors?.required">
                              Este campo es requerido
                          </span>
                        </div>

                        <div class="text-center">
                            <div class="spinner-border text-koaj" role="status" *ngIf="loading">
                                <span class="sr-only">Cargando</span>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary">
                            Enviar
                        </button>
                    </form>

                    <div class="text-center" *ngIf="success">
                        <div class="pb-5">
                            <img src="assets/img/success_contact.svg" alt="Success Contact">
                        </div>
                        <div>
                            <p><strong>Formulario enviado correctamente!</strong></p>
                        </div>
                        <div>
                            <p>Nos pondremos en contacto a la brevedad!</p>

                        </div>
                    </div>
                </div>

                <div class="talk-image">
                    <div class="talk-shape">
                        <img src="assets/img/features/shapee.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="talk-arrow">
                        <img src="assets/img/talk/talk-img2.png" class="wow fadeInRight" data-wow-delay="0.6s" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="talk-content-area">
                    <span>¡Hablemos!</span>
                    <h3>¿Tenés dudas?</h3>
                    <p>¡Llená el formulario y nos pondremos en contacto a la brevedad!</p>

                </div>
            </div>
        </div>
    </div>
</section>