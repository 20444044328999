<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Análisis de resultados</h2>

                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Análisis de resultados</h3>

                        <p>Un paso clave luego de poner en marcha una estrategia para tu negocio es comenzar a analizar si la misma comienza a dar resultados, si debemos realizar modificaciones y ver qué acciones fueron más efectivas que otras. Nuestro equipo se encargará de informarte constantemente de los resultados de las acciones llevadas y te informará sobre aquellas acciones que recomendamos llevar a cabo para <strong>lograr mejores resultados</strong>.</p>


                        <p>
                            ¿No sabes si tus esfuerzos en Analysis y publicidad están dando resultados? ¿Te gustaría tener una visión clara y detallada de cómo está funcionando tu negocio? ¡Entonces nuestro servicio de análisis de resultados es perfecto para vos!
                        </p>

                        <p>Nuestro equipo te proporcionará informes detallados y personalizados para ayudarte a entender exactamente cómo está funcionando tu negocio. Analizamos todo, desde el flujo de views hasta el rendimiento de tus campañas publicitarias, para que puedas tomar decisiones informadas y estratégicas para hacer crecer tu negocio.</p>
                        
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/analysis_1.svg" alt="Makreting">
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services/analysis_2.svg" alt="Analysis">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Información confiable</h3>
                        <p>El servicio de análisis de resultados es completamente personalizado para satisfacer tus necesidades específicas. Nos aseguramos de que tengas acceso a la información más relevante y significativa para tu negocio para que puedas tomar decisiones informadas que te ayuden a lograr tus objetivos.</p>

                        <p>No te conformes con adivinar cómo está funcionando tu negocio. ¡Obtené resultados precisos y confiables con nuestro servicio de análisis de resultados! Contáctanos hoy para obtener más información sobre cómo podemos ayudarte a tomar decisiones informadas para el crecimiento de tu negocio.</p>

                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>




<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Análisis crítico</span>
                    </h3>
                    <p>de los resultados</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Revisiones constantes</span>
                    </h3>
                    <p>cada una de las acciones contarán con seguimiento</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Comunicación permanente</span>
                    </h3>
                    <p>y contacto efectivo</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span style="font-size: 30px">Experiencia</span>
                    </h3>
                    <p>de nuestro equipo multidisciplinario</p>
                </div>
            </div>

        </div>
    </div>
</section>